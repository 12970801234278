var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    { ref: "ruleForm", attrs: { model: _vm.bargeFormData } },
    [
      _c(
        "div",
        { staticClass: "flex-between-row margin-right-10" },
        [
          _c("h1", { staticClass: "sub-module-title" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _vm.termContractType === _vm.TERM_CONTRACT_TYPE.COA
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    round: "",
                    size: "mini",
                    disabled: _vm.isAllFieldsDisabled,
                  },
                  on: { click: _vm.addNewLine },
                },
                [_vm._v(" Add ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "sub-module-block-table" }, [
        _c(
          "div",
          { staticClass: "div-product-other-costs-info-body" },
          [
            _c(
              "el-table",
              {
                ref: "bargeTable",
                attrs: { data: _vm.bargeFormData.barges, border: "" },
              },
              [
                _c("el-table-column", {
                  attrs: { label: "No.", width: "50px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("h5", { staticClass: "h5-el-table-col-index" }, [
                            _vm._v(_vm._s(scope.$index + 1)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { "min-width": "150px" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function () {
                        return [
                          _c("span", [_vm._v("* ")]),
                          _vm._v("Barge Name"),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "barges." + scope.$index + ".id",
                                rules: _vm.rules.notReqRule,
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "popper-class": "custom-ship-selector",
                                    "value-key": "id",
                                    disabled: _vm.isAllFieldsDisabled,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleSelectBarge(
                                        $event,
                                        scope.$index
                                      )
                                    },
                                  },
                                  model: {
                                    value: scope.row.id,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "id", $$v)
                                    },
                                    expression: "scope.row.id",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "inline-block",
                                        width: "200px",
                                        "font-size": "14px",
                                        "font-weight": "500",
                                        "margin-left": "20px",
                                        "margin-bottom": "10px",
                                      },
                                    },
                                    [_vm._v("Name")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "inline-block",
                                        width: "100px",
                                        "font-size": "14px",
                                        "font-weight": "500",
                                      },
                                    },
                                    [_vm._v("IMO")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "inline-block",
                                        "font-size": "14px",
                                        "font-weight": "500",
                                      },
                                    },
                                    [_vm._v("SB No.")]
                                  ),
                                  _vm._l(_vm.bargeOptionList, function (item) {
                                    return _c(
                                      "el-option",
                                      {
                                        key: item.id,
                                        attrs: {
                                          label: item.shipName,
                                          value: item.id,
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              display: "inline-block",
                                              width: "200px",
                                              "font-size": "12px",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.shipName) + " ")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              display: "inline-block",
                                              width: "100px",
                                              "font-size": "12px",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.imo))]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              display: "inline-block",
                                              "font-size": "12px",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.licenceNo))]
                                        ),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { "min-width": "100px" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function () {
                        return [_vm._v("IMO No")]
                      },
                      proxy: true,
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "barges." + scope.$index + ".imoNo",
                                rules: _vm.rules.notReqRule,
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: scope.row.imoNo,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "imoNo", $$v)
                                  },
                                  expression: "scope.row.imoNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { "min-width": "100px" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function () {
                        return [_vm._v("SB No.")]
                      },
                      proxy: true,
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "barges." + scope.$index + ".sbNo",
                                rules: _vm.rules.notReqRule,
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: scope.row.sbNo,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "sbNo", $$v)
                                  },
                                  expression: "scope.row.sbNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm.termContractType === _vm.TERM_CONTRACT_TYPE.COA
                  ? _c("el-table-column", {
                      attrs: { "min-width": "200px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function () {
                              return [
                                _c("span", [_vm._v("* ")]),
                                _vm._v("Date Range"),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop:
                                        "barges." + scope.$index + ".dateRange",
                                      rules: _vm.rules.reqRule,
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "daterange",
                                        "range-separator": "-",
                                        "start-placeholder": "Start Date",
                                        "end-placeholder": "End Date",
                                        disabled: _vm.isAllFieldsDisabled,
                                        "picker-options": _vm.pickerOptions(),
                                      },
                                      on: {
                                        change: (e) =>
                                          _vm.hanleChangeDateRange(
                                            e,
                                            scope.$index
                                          ),
                                      },
                                      model: {
                                        value: scope.row.dateRange,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "dateRange", $$v)
                                        },
                                        expression: "scope.row.dateRange",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3358192297
                      ),
                    })
                  : _vm._e(),
                _vm.termContractType === _vm.TERM_CONTRACT_TYPE.COA
                  ? _c("el-table-column", {
                      attrs: { label: "", width: "90px", fixed: "right" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: {
                                      "confirm-button-text": "Delete",
                                      "cancel-button-text": "No, Thanks",
                                      icon: "el-icon-info",
                                      "icon-color": "red",
                                      title: "Are you sure to delete this?",
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.removeLine(scope.$index)
                                      },
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      attrs: {
                                        slot: "reference",
                                        size: "small",
                                        icon: "el-icon-delete",
                                        type: "danger",
                                        circle: "",
                                        disabled:
                                          _vm.isAllFieldsDisabled ||
                                          _vm.bargeFormData.barges.length === 1,
                                      },
                                      slot: "reference",
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2290159650
                      ),
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }