import request from '@/services/request'
import apis from '@/services/apis'

/*
  Check Barge Availability
*/
export const checkBargeAvailability = (parmas) => {
  return request.get({
    url: `${apis.checkBargeAvailability}?${parmas}`
  })
}
/*
  Modify Term Contract Barges (For COA Buyer Only)
  URL: /term_contracts/termContractId/modify_barges (POST)
*/
export const modifyTermContractBarges = ({ id, barges }) => {
  return request.post({
    url: `${apis.termContract}/${id}/modify_barges`,
    data: barges
  })
}
